.appstore {
  transition: 0.15s;
  color: #fff;
  padding: 2px 28px;
  display: flex;
  height: inherit;
  font-size: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #ffffff4a;
    box-shadow: inset 0 -5px 2px -2px var(--link);
  }
}

.shortcutsContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
