.profileContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    color: white;

    .profileIcon {
        // transition: 0.15s;
        color: #fff;
        // padding: 2px 28px;
        display: flex;
        height: inherit;
        font-size: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .profileActions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    .profileLink {
        display: flex;
        color: white;
        align-items: center;
        gap: 8px;
    }

    .avatar {
        min-width: 32px;
    }

    & > * {
        line-height: normal;
    }
    
}
