.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 8px;

  .headerLogo {
    // color: #fff;
    height: 32px;
    width: 32px;
    cursor: pointer;
    user-select: none;
    object-fit: contain;
  }

  & > * {
    flex: 1;
  }
}
