.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(0, 0, 0, 0.6);

  .highlight {
    color: rgba(3, 184, 255, 0.74);
    cursor: pointer;
  }

  .userAgreement {
    display: flex;
    gap: 8px;
  }

  .userAgreementLogo {
    width: 70px;
  }
}
