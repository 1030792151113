.logo {
    position: absolute;
    left: 24px;
    top: 24px;
    background: #00000021;
    border-radius: 6px;
    padding: 12px;
    width: 192px;
}

.loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // background: url('../../theme/images/backgrounds/login.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.loginWrap {
    width: 50%;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
}

.info {
    padding: 24px;
    background: white;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.title {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 12px;
    display: inline-block;
}

.contactsWrap {
    display: flex;
    justify-content: space-between;
}

.contacts {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.link {
    color: var(--text2);
    white-space: nowrap;
}

.icon {
    margin-right: 8px;
    color: var(--secondary);
}

.socials {
    display: flex;
    gap: 14px;
}

.social {
    width: 42px;
}

.helpWrap {
    white-space: nowrap;
}

.help {
    color: var(--secondary);
    font-weight: 700;
    text-decoration: underline;
}

@media screen and (max-width: 1199px) {
    .logo {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .loginWrap {
        width: 93%;
        flex-direction: column;
    }

    .contactsWrap {
        display: block;

        &>a {
            display: block;
            margin-top: 8px;
        }
    }
}
